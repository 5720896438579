import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import filter from 'lodash/filter';
import moment from 'moment-timezone';
import InsertImages from 'slate-drop-or-paste-images';

import { Editor as SlateEditor } from 'slate-react';
import { Value } from 'slate';
import {createCard} from '../../utils/api';

import '../../styles/feed.scss';
import '../../styles/editor.scss';

import Author from './author';

import { authors, categories } from '../../mockData';

const USER_MENTION_NODE_TYPE = 'userMention';
const CONTEXT_ANNOTATION_TYPE = 'mentionContext';

// Default value for the SlateJS Editor.
const initialValue = Value.fromJSON({
    document: {
        nodes: [
            {
                object: 'block',
                type: 'paragraph',
                nodes: [
                    {
                        object: 'text',
                        text: '',
                    },
                ],
            },
        ],
    },
});

// TODO: Refactor this.
//
// Currently slate throws an error when we upload an image,
// because we aren't actually "rendering" a block anymore.
//
// Now we simply take the image that's dropped and store it
// as a base64 encoding as a property on the card. That's
// fine, but it also means we can drop this plugin and not
// go through the slate editor at all. Instead, expand the
// drop target to accept the image, do a base64 conversion,
// and store it directly itself, without touching the editor.
//
// Delete this plugin, drop the package, and change renderBlock
// to not even touch 'image' tags.

const slatePlugins = [
    InsertImages({
        extensions: ['png'],
        insertImage: (change, file) => {
            var fileReader = new FileReader();
            fileReader.onload = (function(file) {
                return function( event ) {
                    const base64 = event.srcElement.result;
                    return change.insertBlock({
                        type: 'image',
                        isVoid: true,
                        data: { file, base64 }
                    });
                }
            })(file);
            fileReader.readAsDataURL(file);
        }
    })
];

// SlateJS Schema to validate against.
const schema = {
    document: {},
    blocks: {
        image: {
            isVoid: true,
        }
    },
    inlines: {
        [USER_MENTION_NODE_TYPE]: {
            isVoid: true,
        }
    }
}

// Check to see if our input has a `#` for searching for Categories.
function getInput(value) {
    const CAPTURE_REGEX = /#(\S*)$/;

    // In some cases, like if the node that was selected gets deleted,
    // `startText` can be null.
    if (!value.startText) {
        return null
    }

    const startOffset = value.selection.start.offset
    const textBefore = value.startText.text.slice(0, startOffset)
    const result = CAPTURE_REGEX.exec(textBefore)

    return result == null ? null : result[1]
}

class Editor extends Component {
    constructor(props = { onCreate: () => {} }) {
        super(props);

        this.state = {
            value: initialValue,
            categories: categories,
            chosenCategory: null,
            chosenAuthor: authors[0],
            articleImage: '',
            dragging: false,
        }

        // WYSIWYG EDITOR
        this.ref = editor => {
            this.editor = editor;
        }
        this.spellCheck = true;

        this.dropRef = React.createRef();

        this.wordCount = 0;
        this.date = moment().format('ddd, DD MMM, YYYY');

        // DROPDOWNS
        this.dropdownOptions = {
            clearable: true,
            clearOnSelect: true,
            multi: false,
            placeholder: 'Choose Category',
            searchable: true,
            sortBy: 'label'
        };

        this.authors = authors;
        this.defaultAuthor = [authors[0]];

        this.onChange = ({ value }) => {
            // When our text changes, check if it has a #.
            const inputValue = getInput(value);

            // Reset the search before checking for searchability again.
            // Used for things like Pressing Enter. We don't want to keep the
            // options open when you've done a linebreak.
            if (inputValue === null) {
                this.setState({
                    categories: []
                });
            }

            // If it does, we'll do a search.
            if (inputValue !== null && inputValue !== this.lastInputValue) {
                this.lastInputValue = inputValue;
                this.searchCategories(inputValue);

                let annotations = value.annotations.filter(annotation => {
                    return annotation.type !== CONTEXT_ANNOTATION_TYPE;
                });

                const key = 'category_key';
                const { selection } = value;

                annotations = annotations.set(key, {
                    anchor: {
                        key: selection.start.key,
                        offset: selection.start.offset - inputValue.length,
                    },
                    focus: {
                        key: selection.start.key,
                        offset: selection.start.offset,
                    },
                    type: CONTEXT_ANNOTATION_TYPE,
                    key: key,
                });

                this.setState({ value }, () => {
                    // We need to set annotations after the value flushes into the editor.
                    this.editor.setAnnotations(annotations);
                });
                return;
            }

            this.setState({ value });
        }

        this.onKeyDown = (e, change, next) => {
            if (e.key === ' ') {
                return this.onSpace(e, change, next);
            }

            if (e.keyCode === 13 || e.key === 'Enter') {
                return this.onEnter(e, change, next);
            }

            if (e.keyCode === 8 || e.key === 'Backspace') {
                return this.onBackspace(e, change, next);
            }

            // If there isn't a header, set the first block to be a Header.
            const valueJSON = change.value.toJSON();
            const headers = valueJSON.document.nodes.filter(node => {
                return node.type === 'header';
            });

            if (headers.length === 0) {
                return change.setBlocks('paragraph-solo');
            }

            return next();
        }
    }

    /*
     *
     * BLOCKS
     * Eg: Header or Blockquote
     *
    **/

    renderBlock = (props, editor, next) => {
        const { attributes, children, node } = props;

        switch (node.type) {
            case 'header':
                return <h2 {...attributes}>{children}</h2>
            case 'bulleted-list':
                return <ul className="story--bulleted-list" {...attributes}>{children}</ul>
            case 'list-item':
                return <li {...attributes}>{children}</li>
            case 'image':
                const src = node.data.get('base64');
                this.setState({
                    articleImage: src,
                    dragging: false,
                });
                // Do not render anything, it renders at the top instead.
                // This throws an error but cannot be helped at the moment.
                return;
            case 'paragraph-solo':
                return <p className="feed-block--story feed-block--story-solo" {...attributes}>{children}</p>
            case 'paragraph':
                return <p className="feed-block--story" {...attributes}>{children}</p>
            default:
                return <p className="feed-block--story" {...attributes}>{children}</p>
        }
    }

    /*
     *
     * MARKS
     * Eg: Bold, Italic, Underline
     *
    **/

    hasMark = type => {
        const { value } = this.state;
        return value.activeMarks.some(mark => mark.type === type);
    }

    onClickMark = (event, type) => {
        event.preventDefault();
        this.editor.toggleMark(type);
    }

    renderMark = (props, editor, next) => {
        const { children, mark, attributes } = props;

        switch (mark.type) {
            case 'bold':
                return <strong {...attributes}>{children}</strong>
            case 'italic':
                return <em {...attributes}>{children}</em>
            case 'underlined':
                return <u {...attributes}>{children}</u>
            default:
                return next()
        }
    }

    /*
     *
     * CATEGORIES
     *
    **/

    searchCategories(searchQuery) {
        // We don't want to show the wrong options for the current search query, so
        // wipe them out.
        this.setState({
            categories: [],
        })

        if (!searchQuery) {
            return;
        }

        // In order to make this seem like an API call, add a set timeout for some
        // async.
        setTimeout(() => {
            const result = filter(categories, category => {
                return category.id.indexOf(searchQuery) !== -1;
            })

            this.setState({
                categories: result.slice(0, 5),
            })
        }, 50);
    }

    // When a category is chosen from the list, insert it.
    insertCategory = (category) => {
        const value = this.state.value;
        const inputValue = getInput(value);

        this.setState({
            chosenCategory: category,
            categories: []
        }, () => {
            // Remove our text including the # after we choose a category.
            this.editor.deleteBackward(inputValue.length + 1);
            this.lastInputValue = '';
        })
    }

    clearCategory = () => {
        this.setState({ chosenCategory: null});
    }

    // This tells us where to attach the dropdown of options.
    renderAnnotation(props, editor, next) {
        if (props.annotation.type === CONTEXT_ANNOTATION_TYPE) {
            return (
                // Adding the className here is important so that the `Suggestions`
                // component can find an anchor.
                <span {...props.attributes} className="mention-context">
          {props.children}
        </span>
            )
        }

        return next()
    }

    /*
     *
     * IMAGE
     *
    **/

    clearImage = () => {
        this.setState({ articleImage: ''});
    }

    onDragStart = () => {
        this.setState({ dragging: true });
    }

    onDragStop = () => {
        this.setState({ dragging: false });
    }

    componentDidMount() {
        let div = this.dropRef.current;
        div.addEventListener('dragover', this.onDragStart);
        div.addEventListener('dragleave', this.onDragStop);
    }

    /*
     *
     * DATA SERIALIZATION
     *
    **/

    serializeMarks = node => {
        let bold = false;
        let underlined = false;
        let italic = false;
        let text = '';

        if (!node.marks || !node.marks.length) {
            return node.text;
        }

        node.marks.forEach(mark => {
            if (mark.type === 'bold')       { bold = true; }
            if (mark.type === 'italic')     { italic = true; }
            if (mark.type === 'underlined') { underlined = true; }
        });

        if (bold)         { text += '<b>'; }
        if (underlined)   { text += '<u>'; }
        if (italic)       { text += '<em>'; }

        text += node.text.replace(/\n/ig, '');

        if (italic)       { text += '</em>'; }
        if (underlined)   { text += '</u>'; }
        if (bold)         { text += '</b>'; }

        return text;
    }

    serializeList = (list) => {
        let text = '<li>';

        list.nodes.forEach(listItem => {
            if (listItem.marks && listItem.marks.length === 0) {
                text += listItem.text.replace(/\n/ig, '');
            } else {
                text += this.serializeMarks(listItem);
            }
        });

        text += '</li>';
        return text;
    }

    serializeNode = (node) => {
        const nodeText = node.nodes.map(subnode => {
            if (subnode.marks && subnode.marks.length === 0) {
                return subnode.text.replace(/\n/ig, '');
            } else if (subnode.type === 'list-item') {
                return this.serializeList(subnode);
            } else if (subnode.type === 'userMention') {
                return '';
            } else {
                return this.serializeMarks(subnode);
            }
        });
        return nodeText;
    }

    serializeData = () => {
        const cardData = {
            display_date: new Date(),
            additional_properties: {},
            related_content: {
                basic: [],
            },
        };

        if (!this.editor) {
            return cardData;
        }

        const { value } = this.editor;
        const valueJSON = value.toJSON();

        if (this.state.chosenCategory !== null) {
            cardData.additional_properties.category = this.state.chosenCategory;
        }

        if (this.state.chosenAuthor !== null && this.state.chosenAuthor !== undefined) {
            cardData.credits = {
                by: []
            }
            cardData.credits.by.push(this.state.chosenAuthor);
        }

        if (this.state.articleImage !== '') {
            if (!cardData.related_content) {
                cardData.related_content = [];
            }

            const imgContent = {
                caption: '',
                created_date: moment(),
                type: 'image',
                url: this.state.articleImage,
            };

            cardData.related_content.basic.push(imgContent);
        }

        valueJSON.document.nodes.forEach(node => {
            if (node.type === 'header') {
                const nodeText = this.serializeNode(node);

                cardData.headlines = {
                    basic: nodeText.join('')
                }
            }

            if (node.type === 'paragraph' || node.type === 'paragraph-solo') {
                const nodeText = this.serializeNode(node);

                if (!cardData.description || cardData.description.basic === '') {
                    cardData.description = { basic: nodeText.join('') }
                } else {
                    // TODO: Debating making this treat these as <p> tags instead of having
                    // the linebreaks be \n's. Since we have ULs/LIs and A tags stored in this
                    // string already, it may be more stylistically consistent.
                    cardData.description.basic += '\n';
                    cardData.description.basic += nodeText.join('');
                }
            }

            if (node.type === 'bulleted-list') {
                if (!cardData.description || !cardData.description.basic) {
                    cardData.description = { basic: '' }
                }

                const nodeText = this.serializeNode(node);
                cardData.description.basic += `<ul>${nodeText.join('')}</ul>`;
            }
        });

        return cardData;
    }

    /*
     *
     * AUTHOR
     *
    **/

    updateAuthor = (author) => {
        this.setState({
            chosenAuthor: author
        });
    }

    /*
     *
     * KEYBOARD COMMANDS
     *
    **/

    onSpace = (event, editor, next) => {
        const { value } = editor;
        const { selection } = value;

        if (selection.isExpanded) {
            return next();
        }

        const { startBlock } = value;
        const { start } = selection;

        const chars = startBlock.text.slice(0, start.offset).replace(/\s*/g, '');

        if (chars === '-' || chars === '*' || chars === '+') {
            if (startBlock.type === 'list-item') {
                return next();
            }

            event.preventDefault();
            editor.setBlocks('list-item');
            editor.wrapBlock('bulleted-list');
            editor.moveFocusToStartOfNode(startBlock).delete();
        }

        return next();
    }

    onBackspace = (event, editor, next) => {
        const { value } = editor;

        const firstBlock = value.previousBlock === null;
        const lastBlock = value.nextBlock === null;
        const onlyBlock = firstBlock && lastBlock;

        const { focusBlock, document, selection, startBlock } = value;

        // There are a couple of conditionals here related to
        // onlyBlock and firstBlock. SlateJS has a bug in the latest
        // version where deleting the first (or only) text breaks
        // the editor. We can't downgrade slate because of plugin
        // depdencies.

        // So this vaguely-gross hack basically tricks the editor into
        // adding a Space, so that there is always text that cannot be
        // removed, thus not triggering the error state.

        // Some of this code comes from slate-react.es, in the
        // `reconcileNode` function, which is the origin of the failure
        // state.

        const path = document.getPath(focusBlock.key);

        if (onlyBlock && focusBlock.text.length === 1) {
            let entire = selection.moveAnchorTo(path, 0).moveFocusTo(path, 0);
            entire = document.resolveRange(entire);

            editor.insertTextAtRange(entire, ' ');
            editor.deleteBackward(1);
            event.preventDefault();
            return editor.setBlocks('paragraph-solo');
        }

        if (firstBlock) {
            editor.deleteBackward(1);

            let entire = selection.moveAnchorTo(path, 0).moveFocusTo(path, 0);
            entire = selection.moveToEndOfNode(focusBlock);
            entire = document.resolveRange(entire);

            // Don't append more spaces if this already ends in a space.
            if (focusBlock.text.charAt(focusBlock.text.length - 1) !== ' ') {
                editor.insertTextAtRange(entire, ' ');
            } else {
                editor.insertTextAtRange(entire, '');
            }

            event.preventDefault();

            if (lastBlock) {
                return editor.setBlocks('paragraph-solo');
            }
            return;
        }

        if (selection.isExpanded || selection.start.offset !== 0) {
            return next();
        }

        if (startBlock.type === 'paragraph') {
            return next();
        }

        event.preventDefault();
        editor.setBlocks('paragraph');

        if (startBlock.type === 'list-item') {
            editor.unwrapBlock('bulleted-list');
        }
    }

    onEnter = (event, editor, next) => {
        const { value } = editor;
        const { selection, startBlock } = value;
        const { start, end, isExpanded } = selection;
        const firstBlock = value.previousBlock === null;

        if (firstBlock) {
            return editor.setBlocks('header').splitBlock().setBlocks('paragraph');
        }

        if (isExpanded) {
            return next();
        }

        if (start.offset === 0 && startBlock.text.length === 0) {
            return this.onBackspace(event, editor, next);
        }

        if (end.offset !== startBlock.text.length) {
            return next();
        }

        if (startBlock.type  === 'bulleted-list' || startBlock.type === 'list-item') {
            return next();
        }

        event.preventDefault();
        editor.splitBlock().setBlocks('paragraph');
    }

    /*
     *
     * SUBMISSION
     *
     */
    onClickSubmit = async () => {
        const cardData = this.serializeData();
        await createCard(cardData);
        this.reset();
        this.props.onCreate();
    }

    reset() {
        this.setState({
            value: initialValue,
            chosenCategory: null,
            chosenAuthor: authors[0],
            articleImage: '',
        });
    }

    /*
     *
     * RENDERS
     *
    **/

    renderWordCount = () => {
        if (!this.editor) {
            return (
                <span>Word count: 0</span>
            )
        }

        const { value } = this.editor;
        const { document } = value;

        let wordCount = 0;

        for (const [node] of document.blocks({ onlyLeaves: true })) {
            if (node.text.trim() !== '') {
                const words = node.text.trim().split(/\s+/)
                wordCount += words.length
            }
        }

        this.wordCount = wordCount;

        return (
            <span>Word count: {wordCount}</span>
        );
    }

    renderCategoryList = () => {
        const { categories } = this.state;

        if (!categories || categories.length === 0) {
            return;
        }

        const mappedCategories = categories.map((category) => {
            return (
                <li key={category.id} onClick={() => this.insertCategory(category)}>{category.label}</li>
            )
        });

        const anchor = window.document.querySelector('.mention-context');

        if (!anchor) {
            return;
        }

        const anchorRect = anchor.getBoundingClientRect();

        let style = {
            top: anchorRect.bottom,
            left: anchorRect.left,
        };

        return (
            <ul className="category-list" style={style}>
                {mappedCategories}
            </ul>
        )
    }

    renderDropTarget() {
        const { dragging } = this.state;

        if (!dragging) {
            return;
        }

        return (
            <div className="drop-target">Drop an image here to upload</div>
        )
    }

    renderArticleImage() {
        const { articleImage } = this.state;

        if (articleImage === '') {
            return;
        }

        return (
            <div className="article-image">
                <img src={articleImage} alt='article' />
                <span className="clear-option" onClick={() => this.clearImage()}>✕</span>
            </div>

        )
    }

    renderHoverMenu() {
        const { value } = this.state;
        const { fragment, selection } = value;

        if (selection.isBlurred || selection.isCollapsed || fragment.text === '') {
            return (
                <div className="editor-toolbar editor-toolbar--hover" />
            )
        }

        const native = window.getSelection();
        const range = native.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        const style = {
            opacity: 1,
            left: `${rect.left + window.pageXOffset / 2 - 122 / 2}px`,
            top: `${rect.top + window.pageXOffset - 50}px`,
        };

        return (
            <div className="editor-toolbar editor-toolbar--hover" style={style}>
                <button
                    onMouseDown={event => this.onClickMark(event, 'bold')}
                    className={this.hasMark('bold') ? 'button--active' : ''}
                    title="Bold"
                >
                    <strong>B</strong>
                </button>
                <button
                    onMouseDown={event => this.onClickMark(event, 'italic')}
                    className={this.hasMark('italic') ? 'button--active' : ''}
                    title="Italics"
                >
                    <em>I</em>
                </button>
                <button
                    onMouseDown={event => this.onClickMark(event, 'underlined')}
                    className={this.hasMark('underlined') ? 'button--active' : ''}
                    title="Underline"
                >
                    <u>U</u>
                </button>
            </div>
        )
    }

    renderCategorySection() {
        const category = this.state.chosenCategory;

        if (category && category.id) {
            let categoryClass = '';

            if (category.id === 'breaking') {
                categoryClass = 'feed-block--category-breaking';
            }

            return (
                <div className={`feed-block--category ${categoryClass}`}>
                    {category.label}
                    <span className="clear-option" onClick={() => this.clearCategory()}>✕</span>
                </div>
            )
        }
        return;
    }

    render() {
        const categoryClass = this.state.chosenCategory && this.state.chosenCategory.id === 'breaking' ? 'feed-block feed-block--breaking' : 'feed-block feed-block--standard';

        return (
            <section className="section-editor">
                <div className="editor-container">
                    <div className={categoryClass} ref={this.dropRef}>
                        {this.renderDropTarget()}

                        <div className="feed-block--header">
                            {this.renderCategorySection()}
                            {this.renderArticleImage()}
                        </div>

                        <SlateEditor
                            className="editor"
                            value={this.state.value}
                            onChange={this.onChange}
                            onKeyDown={this.onKeyDown}
                            ref={this.ref}
                            placeholder="Write something for Philly"
                            spellCheck={this.spellCheck}
                            renderBlock={this.renderBlock}
                            renderMark={this.renderMark}
                            renderInline={this.renderInline}
                            renderAnnotation={this.renderAnnotation}
                            schema={schema}
                            plugins={slatePlugins}
                        />

                        <div className="feed-block--footer">
                            <Author
                                authors={authors}
                                chosenAuthor={this.state.chosenAuthor}
                                updateAuthor={this.updateAuthor}
                            />
                            <div className="feed-block--date">{this.date}</div>
                        </div>
                    </div>

                    <div className="editor-toolbar">
                        <div className="editor-toolbar--left">
                            {this.renderWordCount()}
                        </div>
                        <div className="editor-toolbar--right">
                            <button
                                className={this.wordCount > 0 ? 'button--active' : 'button--inactive'}
                                onClick={this.onClickSubmit}
                            >
                                Create card
                            </button>
                        </div>
                    </div>
                </div>
                {this.renderHoverMenu()}
                {this.renderCategoryList()}
            </section>
        )
    }
};

export default withRouter(Editor);
