import React from 'react';

import patriotsImg from '../../../img/patriots.png';
import eaglesImg from '../../../img/eagles.jpg';

export default function (props) {
  const storyData = props.story;

  const addprops = storyData.additional_properties;

  const quarter = addprops && addprops.update && addprops.update.quarter ? addprops.update.quarter : '';
  const time = addprops && addprops.update && addprops.update.time_remaining ? addprops.update.time_remaining : '';
  const isLive = addprops && addprops.update && addprops.update.live ? addprops.update.live : false;
  const details = addprops && addprops.update && addprops.update.description ? addprops.update.description : '';
  const teams = addprops && addprops.teams ? addprops.teams : [];

  const getTeamImage = (option) => {
    if (option.name === 'Patriots') {
      return patriotsImg;
    }
    return eaglesImg;
  }

  const teamSection = teams.map((option, i) => {
    return (
      <div key={i} className="feed-block--sports-team">
        <div className="feed-block--sports-team-details">
          <img
            src={getTeamImage(option)}
            alt={option.name}
            className="feed-block--sports-team-image" />
          <div className="feed-block--sports-team-name">
            {option.name}
          </div>
        </div>
        <div className="feed-block--sports-team-score">
          {option.score}
        </div>
      </div>
    )
  });

  const liveSection = () => {
    if (isLive) {
      return (
        <div className="feed-block--sports-live">
          Live
        </div>
      )
    }
    return;
  }

  const detailSection = () => {
    if (details && details !== '') {
      return (
        <div className="feed-block--sports-details">
          {details}
        </div>
      )
    }
    return;
  }

  return (
    <div className="feed-block feed-block--sports">
      <div className="feed-block--sports-teams">
        {teamSection}
      </div>

      <div className="feed-block--sports-update">
        <div className="feed-block--sports-quarter">
          {quarter} Quarter
        </div>
        <div className="feed-block--sports-timing">
          {time}
        </div>
        {liveSection()}
      </div>

      {detailSection()}
    </div>
  )
};
