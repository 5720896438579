import React from 'react';
import InputBlock from '../input-block';

export default function (props) {
  const storyData = props.story;
  const headline = storyData.headlines && storyData.headlines.basic ? storyData.headlines.basic : '';

  const addProps = storyData.additional_properties;
  const callToActionText = addProps && addProps.cta_text ? addProps.cta_text : '';
  const pollOptions = addProps && addProps.cta_buttons ? addProps.cta_buttons : [];
  const displayPercents = addProps && addProps.show_poll_score ? addProps.show_poll_score : false;

  const pollDisplay = pollOptions.map((option, i) => {
    let pollClass = 'feed-block--option';
    let percentsBlock = '';
    let dividerBlock = '';
    let descriptionBlock = '';
    let backgroundBlock = '';

    if (!option.description || option.description === '') {
      pollClass += ' feed-block--option-large';
    } else {
      dividerBlock = <span>{ ' - ' }</span>;
      descriptionBlock = <span>{option.description}</span>;
    }

    if (option && option.score) {
      backgroundBlock = <span className="feed-block--option-background" style={{width: option.score + '%'}}></span>;

      if (displayPercents === true) {
        percentsBlock = <span className="feed-block--option-score">{option.score}%</span>;
      }
    }

    return (
      <li key={i} className={pollClass}>
        <div className="feed-block--option-content">
          <span className="feed-block--label">{option.label}</span>
          {dividerBlock}
          {descriptionBlock}
        </div>
        {percentsBlock}
        {backgroundBlock}
      </li>
    )
  });

  return (
    <InputBlock
      className="feed-block--poll"
      headline={headline}
      callToActionText={callToActionText}
    >
      <ul className="feed-block--options">
        {pollDisplay}
      </ul>
    </InputBlock>
  )
};
