import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URI,
});

export async function getCards() {
    const resp = await api.get('/cards');
    return resp.data;
}

export async function createCard(data) {
    const resp = await api.post('/cards', data);
    return resp.data;
}
