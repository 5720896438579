import React from 'react';

export default function (props) {
  const {
    callToActionText,
    children,
    className,
    headline,
  } = props;

  let feedBlockClass = '';

  if (className) {
    feedBlockClass = className;
  }

  return (
    <div className={`feed-block ${feedBlockClass}`}>

      { /* Headline */ }
      {(() => {
        if (headline) {
          return (
            <div className="feed-block--header">
                <div className="feed-block--header-cta">{callToActionText}</div>
                <h2>{headline}</h2>
            </div>
          )
        }
        return;
      })()}

      <div className="feed-block--content">
        <div className="feed-block--story">
          {children}
        </div>
      </div>
    </div>
  );
}
