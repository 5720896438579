import React from 'react';
import moment from 'moment-timezone';
import { authorImages } from '../../mockData';

export default function (props) {
  const {
    authors,
    category,
    children,
    className,
    headline,
    publishDate,
    relatedContent,
  } = props;

  let feedBlockClass = '';
  let contentClass = '';

  if (className) {
    feedBlockClass = className;
  }

  if (!headline) {
    contentClass = 'feed-block--content-solo';
  }

  const imageSection = () => {
    if (relatedContent.length && relatedContent[0].type === 'image') {
      return (
        <div className="article-image">
          <img src={relatedContent[0].url} alt="article" />
        </div>
      )
    }

    return;
  }

  const storyCategory = () => {
    if (category !== '') {
      let categoryClass = '';

      if (category === 'Breaking') {
        categoryClass = 'feed-block--category-breaking';
      }

      if (relatedContent.length && relatedContent[0].type === 'image') {
        categoryClass += ' feed-block--category-image'
      }

      return (
        <div className={`feed-block--category ${categoryClass}`}>
          {category}
        </div>
      )
    }
  }

  const formatName = (str) => {
    str = str.toLowerCase();
    str = str.split(" ");

    for (var i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }

    return str.join(" ");
  }

  // Only display the first author for now...
  const authorsSection = () => {
    if (authors && authors.length) {
      const author = authors[0];

      const renderAuthorImage = () => {
        const authorImage = authorImages.get(author.value);
        if (authorImage) {
          return (
            <div className="author-image">
              <img src={authorImage} alt={author.name} />
            </div>
          )
        }
        return;
      }

      const authorName = formatName(author.name);

      return (
        <div className="feed-block--authors">
          <span className="author-link">
            {renderAuthorImage()}
            {authorName}
          </span>
        </div>
      )
    } else {
      return;
    }
  }

  const storyDate = () => {
    if (publishDate !== '') {
      const displayDate = moment(publishDate).format('ddd, DD MMM, YYYY');

      return (
        <div className="feed-block--date">{displayDate}</div>
      )
    }
  }

  const overlineSection = () => {
    if (category) {
      return (
        <div className="feed-block--overline">
          {storyCategory()}
        </div>
      )
    }
  }

  const headerSection = () => {
      if (headline) {
        return (
          <div className="feed-block--header">
            <h2>{headline}</h2>
          </div>
        )
      }
  }

  return (
    <div className={`feed-block ${feedBlockClass}`}>

      {overlineSection()}
      {imageSection()}
      {headerSection()}

      <div className={`feed-block--content ${contentClass}`}>
        <div className="feed-block--story">
          {children}
        </div>
      </div>

      { /* Footer */ }
      <div className="feed-block--footer">
        {authorsSection()}
        {storyDate()}
        <div className="feed-block--controls">
          <div className="feed-block--controls-dot"></div>
        </div>
      </div>
    </div>
  );
}
