import React from 'react';

export default function(props) {
  const storyData = props.story;
  const headline = storyData.headlines.basic;

  return (
    <div className="feed-block feed-block--loading">
      { /* Headline */ }
      {(() => {
        if (headline) {
          return (
            <div className="feed-block--header">
              <h2>{headline}</h2>
            </div>
          )
        }
        return;
      })()}

      <div className="feed-block--content">
        {
          storyData.content_elements.map((element, i) =>
            <div key={i} className="feed-block--content-loading"><p>{element.content}</p></div>
          )
        }
      </div>
    </div>
  )
};
