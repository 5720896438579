import React from 'react';
import FeedBlock from '../feed-block';
import ReactHtmlParser from 'react-html-parser';

export default function(props) {
  const storyData = props.story;

  const headline = storyData.headlines && storyData.headlines.basic ? storyData.headlines.basic : '';
  const authors = storyData.credits && storyData.credits.by ? storyData.credits.by : [];
  const category = storyData.additional_properties && storyData.additional_properties.category ? storyData.additional_properties.category.label : '';
  const publishDate = storyData.display_date ? storyData.display_date : '';
  const description = storyData.description && storyData.description.basic ? storyData.description.basic : '';
  const relatedContent = storyData.related_content && storyData.related_content.basic && storyData.related_content.basic.length ? storyData.related_content.basic : [];
  const blockClass = category === 'Breaking' ? 'feed-block--breaking' : 'feed-block--standard';

  return (
    <FeedBlock
      className={blockClass}
      headline={headline}
      authors={authors}
      category={category}
      relatedContent={relatedContent}
      publishDate={publishDate}
    >
      { ReactHtmlParser (description) }
    </FeedBlock>
  )
};
