import React, { Component } from 'react';
import { DropdownList } from 'react-widgets';

import { authorImages } from '../../mockData';

const authorListItem = ({ item }) => {
  if (!item) {
    return;
  }

  const image = authorImages.get(item.value);

  return (
    <div className="author-link">
      <div className="author-image">
        <img src={image} alt={item.name} />
      </div>
      {item.name}
  </div>
  )
};

export default class extends Component {
  render() {
    const updateAuthor = this.props.updateAuthor;

    return (
      <div className="feed-block--authors">
        <DropdownList
          defaultValue={this.props.chosenAuthor}
          data={this.props.authors}
          textField='name'
          valueComponent={authorListItem}
          itemComponent={authorListItem}
          onChange={value => updateAuthor(value)}
        />
      </div>
    )
  }
};
