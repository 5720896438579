import Susan from './img/susan.jpg';
import StephanieFarr from './img/stephanie-farr.png';

const schema = {
  // BASICS
  "_id": String,                          // Unique ANS ID
  "version": String,                      // ANS Schema Version
  "type": String,                         // Static as "story"
  "slug": String,                         // Internal Reference Name
  "language": String,                     // Two Letter Language Code. eg: "en",

  // USEFUL META
  "subtype": String,                      // User-Defined categorization method to supplement type.
                                          // Used for things like selecting PageBuilder Templates.
  "additional_properties": {},            // A grab bag of data that is not validated.
                                          // We can put whatever we want in here.

  // DATES
  "created_date": String,                 // Format for these is 2015-06-24T09:50:50.52Z
  "last_updated_date": String,
  "publish_date": String,
  "display_date": String,
  "first_publish_date": String,           // What is this one used for?

  // LOCATION
  "location": String,                     // City Name
  "geo": {
    "latitude": Number,
    "longitude": Number,
  },
  "address": {                            // Unclear on whether this is where the story takes place.
    "street_addres": String,              // Or whether it is the office publishing the story.
    "extended_address": String,
    "locality": String,
    "region": String,
    "postal_code": String,
    "country_name": String,
  },

  // STORY CONTENT
  "headlines": {
    "basic": String,
    "twitter": String,
  },
  "description": {
    "basic": String,                      // The "Blurb"
  },
  "content_elements": [],                 // See Above Re: Content Schema

  // CONTENT CHANGES
  "editor_note": String,                  // Note from the Editor on the story
  "corrections": [                        // An array of corrections, using the Story Content Type.
    {
      "type": String,                     // Static as "correction"
      "correction_type": String,          // eg "clarification", "correction",
      "text": String,                     // Actual correction text
    },
  ],
  "revision": {
    "revision_id": String,                // The ID of this revision
    "parent_id": String,                  // The ID of the revision that this was branched from.
                                          // Or preceded by if it is the same branch
    "editions": [],                       // Identifiers of editions that point to this revision, eg: "default"
    "branch": Boolean,                    // String, "default" unless otherwise specified
    "published": Boolean,                 // Whether this revision's parent story is published, in any form or place.
    "user_id": String,                    // Who created this revision
  },

  // COMMENTS
  "comments": {
    "allow_comments": Boolean,
    "display_comments": Boolean,
    "moderation_required": Boolean,
  },

  // LINKING & SHARING
  "syndication": {
    "external_distribution": Boolean,     // For third-party clients
    "search": Boolean                     // To filter out articles that are not available via search
  },

  "canonical_website": String,            // The id of the website where this came from, used for multi-sites.
  "canonical_url": String,                // Relative URL to this document on the "canonical_website" website.
  "website": String,                      // Same idea as canonical Website.
  "website_url": String,                  // Same idea as canonical URL.
  "short_url": String,                    // url-shortened version of the Canonical URL, probably for sharing?

  // SEO
  "tracking": {
    "noindex_nofollow": Boolean,
  },

  // CONTENT RESTRICTION
  "content_restrictions": {
    "embargo": {
      "active": Boolean,
      "end_time": String,                 // Date-Time
      "description": String,              // Optional. What the Embargo is.
    },
  },

  // CREDITS & SOURCING
  "credits": {
    "affiliation": [],
    "by": [
      {
        "type": String,                   // Author or Reference
        "name": String,
        "org": String,
      },
    ],
    "photos_by": [],                      // Same as by
  },
  "vanity_credits": {},                   // Identical structure to Credits.
                                          // Used only when ANS is being directly rendered to readers "natively".
                                          // Credits is preferred when converting ANS into feeds or other formats.
  "copyright": String,                    // Copyright for the Legal Owner of the content.
  "owner": {
    "id": String,                         // Identifier of the Distributor whose db has the content.
    "sponsored": Boolean,                 // Whether this content is an advertisement.
  },
  "source": {
    "system": String,                     // The CMS Software used to make this
    "source_id": String,                  // ID in a foreign CMS.
  },
  "distributor": {
    "category": String,                   // Enum for how this content was created.
                                          // "staff", "wires", "freelance", "stock", "handout", "other"
    "name": String,
  },

  // AUDIO TRANSCRIPTS
  "voice_transcripts": [
    {                                     // More in audio.json
      "type": String,                     // Static as "voice_transcript"
      "subtype": String,                  // "short" or "long" are likely to be used.

      "options": {
        "enabled": Boolean,               // Whether to use this I guess?
        "voice": String,                  // The "Voice" used to speak the text.
      },
      "options_used": {},                 // Identical to Options
                                          // Unless there is a need for a fallback or something?
      "output": {
        "type": String,                   // Static as "audio",
        "streams": [                      // More in audio_stream.json
          {
            "url": String,                // Url of the audio file
          },
        ],
      }
    },
  ],

  // PLANNING & STATE
  "status": String,                       // Corresponds to story workflow.
  "workflow": {
    "status_code": Number,                // Corresponds to value in WebSked.
    "note": String,                       // Used for any task automatically generated via WebSked triggers.
  },
  "planning": {
    "websked_sort_date": String,          // Date / Time for chronological sorting in WebSked.
    "deadline_miss": Number,              // Minutes between planned and actual publish times.
    "internal_note": String,              // For shared communication inside the newsroom.
    "budget_line": String,                // Identify what the story is about internally
    "scheduling": {
      "planned_publish_date": String,     // When this is intended to be published.
      "scheduled_publish_date": String,   // When this was first published.
      "will_have_gallery": Boolean,
      "will_have_graphic": Boolean,
      "will_have_image": Boolean,
      "will_have_video": Boolean,
    },
    "story_length": {
      "word_count_planned": Number,       // Estimated length of story in words
      "word_count_actual": Number,        // Current number of words
      "inch_count_planned": Number,       // Estimated length of story in inches
      "inch_count_actual": Number,        // Current length of story in inches
      "line_count_planned": Number,       // Esimated length of story in lines
      "line_count_actual": Number,        // Current length of story in lines
    },
  },

  // LINKED CONTENT
  "related_content": {
    "basic": [],
  },
  "promo_items": {
    "basic": {},
    "second": {},
  },
  "taxonomy": {
    "keywords": [],
    "primary_site": {},
    "topics": [],
    "seo_keywords": [],
    "tags": [],
    "sites": [],
  },

  // UNCLEAR Section
  "alignment": String,                    // Left, Right, Center. Horizontal positioning.
                                          // I don't know why that's on the Story itself.
                                          // Maybe for References to be used as Teases?
  "content_aliases": [],                  // An array of different names that this content
                                          // can be fetched by instead of ID.
                                          // I have no idea what the use case is for this.
  "label": {},                            // I think this is like Tags?
                                          // But that also exists within Taxonomy
};

const categories = [
  {
      id: 'wethepeople',
      label: 'We the People'
  },
  {
      id: 'breaking',
      label: 'Breaking'
  },
  {
      id: 'publictransit',
      label: 'Public Transit'
  },
  {
      id: 'opinion',
      label: 'Opinion'
  },
  {
      id: 'curiousphilly',
      label: 'Curious Philly'
  },
  {
      id: 'phillies',
      label: 'Phillies'
  }
];

const authors = [
  {
    "value": "stephanie-farr",
    "type": "author",
    "name": "Stephanie Farr",
  },
  {
    "value": 'susan-snyder',
    "type": "author",
    "name": "Susan Snyder",
  },
];

const authorImages = new Map([
  ['stephanie-farr', StephanieFarr],
  ['susan-snyder', Susan],
]);

const loadingData = [
  {
    "subtype": 'placeholder',
    "headlines": {
      "basic": "Comcast Tower’s Four Seasons hotel gives 60th-story views along with the florist for the Kardashians",
    },
    "content_elements": [
      {
        "type": "text",
        "content": "Comcast Corp., which owns the Flyers, Wells Fargo Center, local cable systems, a new arena for esports, and NBC10, will notch another Philly franchise on Monday: a high-end hotel with unmatched views."
      },
      {
        "type": "text",
        "content": "The 500-employee Four Seasons on the top 12 floors of the new Comcast Technology Center opens after a “soft launch” in recent weeks, with hotel employees and guests using an entrance and elevators at the opposite side of the tower, at 19th and Arch Streets."
      },
    ]
  },
  {
    "subtype": "placeholder",
    "headlines": {
      "basic": "Nearly 3 in 10 Philadelphians know someone who’s died from opioid use, Pew survey finds"
    },
    "content_elements": [
      {
        "type": "text",
        "content": "In a stark illustration of the depths of Philadelphia’s opioid crisis, a new survey of city residents found that nearly a third — 29% — know someone who has died from opioid use."
      },
      {
        "type": "text",
        "content": "The survey, conducted by the Pew Charitable Trusts among 600 Philadelphians this spring, was an attempt to fill in some of the gaps that still exist in data surrounding the crisis."
      },
      {
        "type": "text",
        "content": "“We have the death rates,” said Larry Eichel, the director of Pew’s Philadelphia Research Initiative — 1,217 overdose deaths in 2017 and 1,116 in 2018, the vast majority of them from the deadly synthetic opioid fentanyl. “But there wasn’t much data of the impact of the crisis on residents in the neighborhoods. That’s what we wanted to look at.”"
      },
    ]
  }
];


export {
  schema,
  authors,
  authorImages,
  categories,

  loadingData,
};
