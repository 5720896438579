import React, { Component } from 'react';

import { Poll, Question } from './feed/interactives/index';
import { Sports } from './feed/other/index';
import { Standard } from './feed/stories/index';
import Loading from './feed/loading-block';

export default class Feed extends Component {
  render() {
    const feedItems = this.props.feedItems;

    const stories = feedItems.map((story, index) => {
      switch(story.subtype) {
        case 'poll':
          return <Poll key={index} story={story} />
        case 'question':
          return <Question key={index} story={story} />
        case 'sports':
          return <Sports key={index} story={story} />
        case 'standard':
          return <Standard key={index} story={story} />
        case 'placeholder':
          return <Loading key={index} story={story} />
        default:
          return <Standard key={index} story={story} />
      }
    });

    return (
      <section className="section-feed">
        <div className="feed-inner">
          {stories}
        </div>
      </section>
    )
  }
}
