import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Feed, Live } from './pages';

function App() {
  return (
    <div className="App">
      <Router>
        <Route exact path="/" component={Feed} />
        <Route path="/live" component={Live} />
      </Router>
    </div>
  );
}

export default App;
