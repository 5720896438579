import React, { Component } from 'react';
import fetch from 'universal-fetch';
import FeedList from '../components/feedList';

import { loadingData } from '../mockData';

import '../styles/feed.scss';

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedItems: loadingData
    };
  }

  componentDidMount() {
    fetch('https://www.inquirer.com/arcio/ans/')
      .then(response => response.json())
      .then(data => {
        this.setState({
          feedItems: data
        });
      });
  }

  render() {
    return (
      <section className="live-feed">
        <FeedList feedItems={this.state.feedItems} />
      </section>
    )
  }
};
