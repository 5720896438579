import React, { Component } from 'react';

import FeedList from '../components/feedList';
import { getCards } from '../utils/api';

import { loadingData } from '../mockData';
import Editor from "../components/create/editor";

export default class Feed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      feedItems: loadingData
    };
  }

  componentDidMount() {
    this.refreshFeed();
  }

  async refreshFeed() {
    const feedItems = await getCards();
    this.setState({
      feedItems,
    });
  }

  onCreate = () => {
    this.refreshFeed();
  }

  render() {
    return (
      <React.Fragment>
        <FeedList feedItems={this.state.feedItems} />
        <Editor onCreate={this.onCreate} />
      </React.Fragment>
    )
  }
}
