import React from 'react';
import InputBlock from '../input-block';

export default function (props) {
  const storyData = props.story;
  const headline = storyData.headlines && storyData.headlines.basic ? storyData.headlines.basic : '';
  const description = storyData.description && storyData.description.basic ? storyData.description.basic : '';

  const addProps = storyData.additional_properties;
  const callToActionText = addProps && addProps.cta_text ? addProps.cta_text : '';
  const placeholder = addProps && addProps.question_placeholder ? addProps.question_placeholder : '';

  const descriptionSection = () => {
    if ((!headline || headline === '') && (description && description !== '')) {
      return (
        <div className="feed-block--input-description">
          {description}
        </div>
      )
    } else {
      return;
    }
  }

  const promptSection = () => {
    if (headline && headline !== '') {
      return (
        <input className="feed-block--input feed-block--input-box" placeholder={placeholder} />
      )
    } else {
      return (
        <input className="feed-block--input feed-block--input-line" placeholder={placeholder} />
      )
    }
  }

  return (
    <InputBlock
      className="feed-block--question"
      headline={headline}
      callToActionText={callToActionText}
    >
      <div className="feed-block--form">
        {descriptionSection()}
        {promptSection()}
      </div>
    </InputBlock>
  )
};


